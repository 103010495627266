<template>
  <div>
    <v-container class="d-flex flex-row align-end">
      <v-icon>search</v-icon>
      <y-company-select v-model="filters.company"/>
    </v-container>
    <v-data-table
        :footer-props="{'items-per-page-options': [10, 20, 50, 100]}"
        :headers="headers"
        :items="items"
        :items-per-page="10"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
        @click:row="$emit('display-selected', $event)">
      >
      <template v-slot:[`item.companyUuid`]="{ item }">
        <y-company-label :value="item.companyUuid" :copy-on-click="false" @click="onCompanyClick" show-tooltip-only-on-error/>
      </template>
      <template v-slot:[`item.branchUuid`]="{ item }">
        <y-branch-label v-if="item.branchUuid && item.branchUuid.length === 32" :value="item.branchUuid" :copy-on-click="false" @click="onCompanyClick" show-tooltip-only-on-error/>
        <span v-else>{{item.branchUuid}}</span>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        <span>{{ labelForTypeEnum(item.type) }}</span>
      </template>
      <template v-slot:[`item.mediums`]="{ item }">
        <display-medium-type-chip  v-for="(m,i) of item.mediums" :key="i" :value="m" class="mr-1"/>
      </template>
      <template v-slot:[`item.actions`]="{  }">
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DisplayMediumTypeChip from "@/components/DisplayMediumTypeChip";
export default {
  name: "DisplayTable",
  components: {DisplayMediumTypeChip},
  data: () => ({
    filters: {
      company: null
    },
    headers: [
      {text: "Société", value: "companyUuid", sortable: false},
      {text: "Agence", value: "branchUuid", sortable: false},
      {text: "Type", value: "type", sortable: false},
      {text: "Mediums", value: "mediums", sortable: false},
      // {text: "", value: "actions", sortable: false},
    ],
    items: [],
    loading: false,
    options: {},
    totalItems: 0,
    error: false,
    downloadError: false
  }),
  watch: {
    options: {
      deep: true, handler: function () {
        this.fetchDisplays()
      }
    },
    filters: {
      deep: true, handler: function () {
        this.fetchDisplays()
      }
    }
  },
  mounted() {
    this.fetchDisplays();
  },
  methods: {
    onCompanyClick(companyUuid) {
      this.filters.company = companyUuid;
    },
    labelForTypeEnum(type) {
        switch (type) {
          case 'CURRENCY': return 'Devises';
          case 'METAL': return 'Métaux';
          case 'CAST': return 'Fonte';
          default: return 'N/A'
      }
    },
    fetchDisplays() {
      this.loading = true;
      this.error = false;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      let url = this.$config.apiUrl
          + "/v1/displays?page=" + (page - 1)
          + "&size=" + itemsPerPage
          // + "&" + this.filters.status + "=true";

      if (this.filters.company) {
        url += "&companyUuid=" + this.filters.company.uuid
      }

      if (sortBy.length) {
        url += "&sort=" + sortBy[0] + "," + (sortDesc[0] ? 'desc' : 'asc');
      }

      this.$http.get(url)
          .then(r => {
            this.items = r.data.content;
            this.totalItems = r.data.totalElements;
          })
          .catch(() => this.error = true)
          .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>
